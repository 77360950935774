import _ from 'lodash';
import Key from './Key';

class Keys {

  static Fs_MAJOR = new Key(['f#', 'g#', 'a#', 'b', 'c#', 'd#', 'f'], 'F#', 'F# Major');
  static Eb_MINOR = new Key(relativeMinor(Keys.Fs_MAJOR, 'eb'), 'Ebm', 'Eb Minor');

  static Db_MAJOR = new Key(['db', 'eb', 'f', 'gb', 'ab', 'bb', 'c'], 'Db', 'Db Major');
  static Bb_MINOR = new Key(relativeMinor(Keys.Db_MAJOR, 'bb'), 'Bbm', 'Bb Minor');

  static Ab_MAJOR = new Key(['ab', 'bb', 'c', 'db', 'eb', 'f', 'g'], 'Ab', 'Ab Major');
  static F_MINOR = new Key(relativeMinor(Keys.Ab_MAJOR, 'f'), 'Fm', 'F Minor');

  static Eb_MAJOR = new Key(['eb', 'f', 'g', 'ab', 'bb', 'c', 'd'], 'Eb', 'Eb Major');
  static C_MINOR = new Key(relativeMinor(Keys.Eb_MAJOR, 'c'), 'Cm', 'C Minor');

  static Bb_MAJOR = new Key(['bb', 'c', 'd', 'eb', 'f', 'g', 'a'], 'Bb', 'Bb Major');
  static G_MINOR = new Key(relativeMinor(Keys.Bb_MAJOR, 'g'), 'Gm', 'G Minor');

  static F_MAJOR = new Key(['f', 'g', 'a', 'bb', 'c', 'd', 'e'], 'F', 'F Major');
  static D_MINOR = new Key(relativeMinor(Keys.F_MAJOR, 'd'), 'Dm', 'D Minor');

  static C_MAJOR = new Key(['c', 'd', 'e', 'f', 'g', 'a', 'b'], 'C', 'C Major');
  static A_MINOR = new Key(relativeMinor(Keys.C_MAJOR, 'a'), 'Am', 'A Minor');

  static G_MAJOR = new Key(['g', 'a', 'b', 'c', 'd', 'e', 'f#'], 'G', 'G Major');
  static E_MINOR = new Key(relativeMinor(Keys.G_MAJOR, 'e'), 'Em', 'E Minor');

  static D_MAJOR = new Key(['d', 'e', 'f#', 'g', 'a', 'b', 'c#'], 'D', 'D Major');
  static B_MINOR = new Key(relativeMinor(Keys.D_MAJOR, 'b'), 'Bm', 'B Minor');

  static A_MAJOR = new Key(['a', 'b', 'c#', 'd', 'e', 'f#', 'g#'], 'A', 'A Major');
  static Fs_MINOR = new Key(relativeMinor(Keys.A_MAJOR, 'f#'), 'F#m', 'F# Minor');

  static E_MAJOR = new Key(['e', 'f#', 'g#', 'a', 'b', 'c#', 'd#'], 'E', 'E Major');
  static Cs_MINOR = new Key(relativeMinor(Keys.E_MAJOR, 'c#'), 'C#m', 'C# Minor');

  static B_MAJOR = new Key(['b', 'c#', 'd#', 'e', 'f#', 'g#', 'a#'], 'B', 'B Major');
  static Gs_MINOR = new Key(relativeMinor(Keys.B_MAJOR, 'g#'), 'G#m', 'G# Minor');
}


function relativeMinor(key, startingNote) {
  const start = _.indexOf(key.pitches, startingNote);
  return _.concat(
    _.slice(key.pitches, start, key.pitches.length),
    _.slice(key.pitches, 0, start)
  )
}

export default Keys;