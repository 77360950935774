import React, {Component} from 'react';

class ModeButton extends Component {

  render() {
    const current = this.props.currentMode === this.props.targetMode;
    return <label>
      <input type="radio" name="mode" onClick={this.update} defaultChecked={current} />
      <span>{this.props.targetMode}</span>
    </label>
  }

  update = (e) => {
    this.props.myHandler(e, this.props.targetMode)
  }
}

export default ModeButton;