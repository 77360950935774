class Key {
  constructor(pitches, vexFlowLabel, humanLabel) {
    this.pitches = pitches;
    this.vexFlowLabel = vexFlowLabel;
    this.humanLabel = humanLabel;
  }

  accessibleLabel() {
    const parts = this.humanLabel.split(" ");
    switch (this.humanLabel.charAt(1)) {
      case 'b': return this.humanLabel.charAt(0) + ' flat ' + parts[1];
      case '#': return this.humanLabel.charAt(0) + ' sharp ' + parts[1];
      default: return this.humanLabel;
    }
  }
}

export default Key