import React, {Component} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch
} from 'react-router-dom';

// import logo from './logo.svg';
import './sass/main.scss';
import ModeComponent from './ModeComponent';
import HomeComponent from './HomeComponent';

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <div className="App-header">
            <h1><Link to="/">Scale Shed</Link></h1>
            <nav>
              <ol>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/modes">Modes</Link></li>
              </ol>
            </nav>
          </div>
          <Switch>
            <Route exact path="/" component={HomeComponent}/>
            <Route path="/modes" component={ModeComponent}/>
            <Route component={error_404}/>
          </Switch>
        </div>
      </Router>
    );
  };
}

function error_404({location}) {
  return (
    <div>
      <h3>
        Page not found
      </h3>
    </div>
  );
}

export default App;
