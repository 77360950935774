import Pitches from './Pitches.js';

class Modes {

  /*
    n = pitch
    n.25 = pitch - semitone
    n.75 = pitch + semitone
   */

  static ionian(key) {
    return toPitches(key, [1, 2, 3, 4, 5, 6, 7]);
  };

  static dorian(key) {
    return toPitches(key, [1, 2, 3.25, 4, 5, 6, 7.25]);
  }

  static phrygian(key) {
    return toPitches(key, [1, 2.25, 3.25, 4, 5, 6.25, 7.25]);
  }

  static lydian(key) {
    return toPitches(key, [1, 2, 3, 4.75, 5, 6, 7]);
  }

  static mixolydian(key) {
    return toPitches(key, [1, 2, 3, 4, 5, 6, 7.25]);
  }

  static aeolian(key) {
    return toPitches(key, [1, 2, 3.25, 4, 5, 6.25, 7.25]);
  }

  static locrian(key) {
    return toPitches(key, [1, 2.25, 3.25, 4, 5.25, 6.25, 7.25]);
  }

}

function toPitches(key, tones) {
  return tones.map(function (curr) {
    const floor = Math.floor(curr);
    const scaleNote = key.pitches[floor - 1];
    switch (curr - floor) {
      case 0:
        return scaleNote;
      case 0.25:
        return Pitches.flatten(scaleNote);
      case 0.75:
        return Pitches.sharpen(scaleNote);
      default: return scaleNote;
    }
  })
}

export default Modes;