import React, {Component} from 'react';

class ClefButton extends Component {

  render() {
    const current = this.props.currentClef === this.props.targetClef;
    return <label>
      <input type="radio" name="clef" onClick={this.update} defaultChecked={current} />
      <span>{this.props.targetClef}</span>
    </label>
  }

  update = (e) => {
    this.props.myHandler(e, this.props.targetClef)
  }
}

export default ClefButton;