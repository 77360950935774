
class Pitches {

  static tones = ['c', 'd', 'e', 'f', 'g', 'a', 'b'];

  static sharpen(tone) {
    if (tone.length === 1)
      return tone + '#';
    else if (tone.endsWith('b'))
      return tone.charAt(0);
    else if (tone.endsWith('#'))
      return Pitches.tones[(Pitches.tones.indexOf(tone.charAt(0)) + 1) % Pitches.tones.length];
  }

  static flatten(tone) {
    if (tone.length === 1)
      return tone + 'b';
    else if (tone.endsWith('#'))
      return tone.charAt(0);
    else if (tone.endsWith('b'))
      return Pitches.tones[(Pitches.tones.indexOf(tone.charAt(0)) - 1 + Pitches.tones.length) % Pitches.tones.length];
  }
}

export default Pitches;