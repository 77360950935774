import React, {Component} from 'react';
import {Link} from "react-router-dom";

class HomeComponent extends Component {

    render() {
        return (
            <div className="home">
                <section>
                    <h1>Wait, what is this?</h1>
                    <p>A hobby project to play with presenting practice material online.</p>
                    <p>There ought to be a bunch of tools that can be written to aid practice that benefit from what
                        computers are good at.
                        This is a work-in-progress to explore what can be done and what can be useful.
                    </p>
                </section>
                <section>
                    <h1>So, what have we got?</h1>
                    <p>So far, <Link to="/modes">a tool for learning modes</Link>.</p>
                    <p>Rather than pages upon pages of manuscript, there's a little
                        script that can generate any mode, in any key, in any clef, in any octave.
                    </p>
                </section>
                <section>
                    <h1>What else could we do?</h1>
                    <p>Oooh, lots. It ought not to be rocket science to generate all the scales
                        listed in Slonimsky's <a target="_blank" rel="noopener noreferrer"
                                                 href="https://books.google.co.uk/books?id=TFhStAEACAAJ&dq=thesaurus+of+scales+and+melodic+patterns&hl=en&sa=X&ved=0ahUKEwj1-t3D2J3gAhWTQhUIHXN6AagQ6AEIKjAA">Thesaurus
                            of Scales and Melodic Patterns</a>.
                        Or to list all the modes and scales that can be used over a given chord.
                        But perhaps most interesting would be to auto-generate a literally endless
                        stream of sheet music to practice sight reading.
                    </p>
                </section>
                <section>
                    <h1>I've had an idea - can you do "X"?</h1>
                    <p>Dunno. Maybe? I'd love to hear your ideas. <a href="https://twitter.com/mgladdish">Let me
                        know</a>.</p>
                </section>
                <section>
                    <h1>This sounds dead clever and I want a go at doing something like it. What tools does this
                        use?</h1>

                    Lots.
                    <ul>
                        <li>The webapp is built in <a href="https://reactjs.org">react</a>.</li>
                        <li>Styles, such as they are, are written in <a href="https://sass-lang.com">Sass</a>.</li>
                        <li>The clever runtime manuscript rendering is done in <a
                            href="http://www.vexflow.com">Vexflow</a>.
                        </li>
                        <li>And it's all hosted on <a href="https://aws.amazon.com">AWS</a> for a pittance.</li>
                    </ul>

                </section>
            </div>
        )
    }
}

export default HomeComponent;
