import React, {Component} from 'react';

class KeyButton extends Component {

  render() {
    const current = this.props.currentKey === this.props.targetKey;
    return <label>
      <input type="radio" name="key" onClick={this.update} aria-label={this.props.targetKey.accessibleLabel()} defaultChecked={current} />
      <span>{this.props.targetKey.humanLabel}</span>
    </label>
  }

  update = (e) => {
    this.props.myHandler(e, this.props.targetKey)
  }
}

export default KeyButton;