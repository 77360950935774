import Vex from 'vexflow';
import _ from 'lodash';
import Pitches from './Pitches';

class Scale {

  static fromPitches(pitches, clef = 'bass', startingOctave = 3) {

    const offset = Pitches.tones.indexOf(pitches[0].charAt(0));

    function octave(startingOctave, pitch, index) {
      return startingOctave + Math.floor((index + offset) / Pitches.tones.length);
    }

    const ascendingNotes = pitches.map(function (pitch, index) {
      return new Vex.Flow.StaveNote({
        clef: clef,
        keys: [pitch + '/' + octave(startingOctave, pitch, index)],
        duration: "8"
      })
    });

    const ascending = _.concat(_.cloneDeep(ascendingNotes), new Vex.Flow.StaveNote({
      clef: clef,
      keys: [pitches[0] +'/' + octave(startingOctave, pitches[0], pitches.length)],
      duration: "8"
    }));

    const descendingNotes = _.reverse(_.cloneDeep(ascendingNotes));
    const descending = _.concat(_.dropRight(descendingNotes, 1), new Vex.Flow.StaveNote({
      clef: clef,
      keys: [pitches[0] + '/' + startingOctave],
      duration: "4"
    }));

    return [
      new Vex.Flow.Voice({num_beats: 4, beat_value: 4, clef: clef}).addTickables(ascending),
      new Vex.Flow.Voice({num_beats: 4, beat_value: 4, clef: clef}).addTickables(descending)
    ]
  }
}

export default Scale