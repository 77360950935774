import React, {Component} from 'react';
import Vex from 'vexflow';
import Scale from './model/Scale';
import Keys from './model/Keys';
import Modes from './model/Modes';
import KeyButton from './KeyButton';
import ModeButton from './ModeButton';
import ClefButton from './ClefButton';

class ModeComponent extends Component {

  _X_OFFSET = 10;
  vf = undefined;
  renderer = undefined;
  x = this._X_OFFSET;
  y = 80;

  firstBarWidth = 350;
  subsequentBarWidth = 300;

  constructor(props) {
    super(props);
    this.state = {
      key: Keys.C_MAJOR,
      mode: 'ionian',
      clef: 'bass',
      startingOctave: 3
    };
  }

  reset = () => {
    this.x = this._X_OFFSET;
    this.y = 80;
    const con = this.renderer.getContext();
    con.svg.remove();
    this.renderer = this.buildRenderer();
  };

  toKey = (e, key) => {
    this.reset();
    this.setState({
      key: key
    });
  };

  toMode = (e, mode) => {
    this.reset();
    this.setState({
      mode: mode
    });
  };

  toClef = (e, clef) => {
    this.reset();
    this.setState({
      clef: clef
    })
  };

  toOctave = (e) => {
    this.reset();
    this.setState({
      startingOctave: parseInt(e.target.value, 10)
    })
  };

  render() {
    return (
      <div>
        <section className="modeOptions">
          <fieldset>
            <legend>Choose a key</legend>
            <KeyButton currentKey={this.state.key} targetKey={Keys.C_MAJOR} myHandler={this.toKey}/>
            <KeyButton currentKey={this.state.key} targetKey={Keys.Db_MAJOR} myHandler={this.toKey}/>
            <KeyButton currentKey={this.state.key} targetKey={Keys.D_MAJOR} myHandler={this.toKey}/>
            <KeyButton currentKey={this.state.key} targetKey={Keys.Eb_MAJOR} myHandler={this.toKey}/>
            <KeyButton currentKey={this.state.key} targetKey={Keys.E_MAJOR} myHandler={this.toKey}/>
            <KeyButton currentKey={this.state.key} targetKey={Keys.F_MAJOR} myHandler={this.toKey}/>
            <KeyButton currentKey={this.state.key} targetKey={Keys.Fs_MAJOR} myHandler={this.toKey}/>
            <KeyButton currentKey={this.state.key} targetKey={Keys.G_MAJOR} myHandler={this.toKey}/>
            <KeyButton currentKey={this.state.key} targetKey={Keys.Ab_MAJOR} myHandler={this.toKey}/>
            <KeyButton currentKey={this.state.key} targetKey={Keys.A_MAJOR} myHandler={this.toKey}/>
            <KeyButton currentKey={this.state.key} targetKey={Keys.Bb_MAJOR} myHandler={this.toKey}/>
            <KeyButton currentKey={this.state.key} targetKey={Keys.B_MAJOR} myHandler={this.toKey}/>
          </fieldset>
          <fieldset>
            <legend>Choose a mode</legend>
            <ModeButton currentMode={this.state.mode} targetMode={'ionian'} myHandler={this.toMode}/>
            <ModeButton currentMode={this.state.mode} targetMode={'dorian'} myHandler={this.toMode}/>
            <ModeButton currentMode={this.state.mode} targetMode={'phrygian'} myHandler={this.toMode}/>
            <ModeButton currentMode={this.state.mode} targetMode={'lydian'} myHandler={this.toMode}/>
            <ModeButton currentMode={this.state.mode} targetMode={'mixolydian'} myHandler={this.toMode}/>
            <ModeButton currentMode={this.state.mode} targetMode={'aeolian'} myHandler={this.toMode}/>
            <ModeButton currentMode={this.state.mode} targetMode={'locrian'} myHandler={this.toMode}/>
          </fieldset>
          <fieldset>
            <legend>Choose a clef</legend>
            <ClefButton currentClef={this.state.clef} targetClef={'bass'} myHandler={this.toClef}/>
            <ClefButton currentClef={this.state.clef} targetClef={'tenor'} myHandler={this.toClef}/>
            <ClefButton currentClef={this.state.clef} targetClef={'alto'} myHandler={this.toClef}/>
            <ClefButton currentClef={this.state.clef} targetClef={'treble'} myHandler={this.toClef}/>
          </fieldset>
          <label>Choose a starting octave
            <input type="number" value={this.state.startingOctave} min="-1" max="7" onChange={this.toOctave} name="startingOctave"/>
          </label>
        </section>
        <section id="mystave" className="svg-container"/>
        {this.drawVexFlow()}
      </div>
    )
  }

  makeStave(width) {
    const stave = new Vex.Flow.Stave(this.x, this.y, width);
    this.x += width;
    return stave;
  }

  static barsToAccessibleDescription(bars) {
    return bars.map(function (bar) {
      return bar.getTickables().map(function (note) {
        const noteAndDuration = note.keys[0].split('/');
        if (noteAndDuration[0].length === 2) {
          if (noteAndDuration[0].endsWith('b')) {
            return noteAndDuration[0].charAt(0) + ' flat ' + noteAndDuration[1];
          }
          else {
            return noteAndDuration[0].charAt(0) + ' sharp ' + noteAndDuration[1];
          }
        }
        else {
          return noteAndDuration[0] + ' ' + noteAndDuration[1];
        }
      })
    })
  }

  drawVexFlow() {
    if (!!this.renderer) {
      const context = this.renderer.getContext();

      const bars = Scale.fromPitches(Modes[this.state.mode](this.state.key), this.state.clef, this.state.startingOctave);

      const svgElem = context.svg;
      svgElem.setAttribute('class', 'svg-content');
      svgElem.setAttribute('role', 'img');
      svgElem.setAttribute('aria-labelledby', 'scaleManuscriptTitle scaleManuscriptDescription');
      const title = document.createElement('title');
      title.textContent = 'Manuscript of the ' + this.state.mode + ' mode in ' + this.state.key.humanLabel;
      title.setAttribute('id', 'scaleManuscriptTitle');

      const desc = document.createElement('description');
      desc.textContent = ModeComponent.barsToAccessibleDescription(bars);
      desc.setAttribute('id', 'scaleManuscriptDescription');

      svgElem.appendChild(title);
      svgElem.appendChild(desc);

      bars.forEach(function (bar, index) {
        const stave = (index === 0) ? this.makeStave(this.firstBarWidth) : this.makeStave(this.subsequentBarWidth);
        if (index === 0) {
          stave.addClef(this.state.clef).addTimeSignature("4/4").addKeySignature(this.state.key.vexFlowLabel);
        }
        if (index === bars.length - 1) {
          stave.setEndBarType(Vex.Flow.Barline.type.END)
        }
        stave.setContext(context).draw();
        const beams = Vex.Flow.Beam.generateBeams(bar.getTickables());
        Vex.Flow.Accidental.applyAccidentals([bar], this.state.key.vexFlowLabel);
        Vex.Flow.Formatter.FormatAndDraw(context, stave, bar.getTickables());
        beams.forEach(function (beam) {
          beam.setContext(context).draw();
        })
      }, this);
    }
  }

  svgWidth() {
    const noBars = 2;
    return ((noBars - 1) * this.subsequentBarWidth) + this.firstBarWidth + (this._X_OFFSET * 2)
  }

  componentDidMount() {
    this.renderer = this.buildRenderer();
    this.drawVexFlow();
  }

  buildRenderer() {
    const div = document.getElementById("mystave");
    const renderer = new Vex.Flow.Renderer(div, Vex.Flow.Renderer.Backends.SVG);
    const context = renderer.getContext();
    context.setViewBox(0, 0, this.svgWidth(), 300);
    return renderer;
  }
}

export default ModeComponent;